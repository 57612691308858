import './styles/globals.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { MetaMaskProvider } from 'metamask-react'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <MetaMaskProvider>
      <Router>
        <App path={'payment/:tx' || '/:tx'} />
      </Router>
    </MetaMaskProvider>
  </React.StrictMode>
)