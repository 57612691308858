import PolygonIcon from '../assets/images/crypto/polygon.png'
import HardhatIcon from '../assets/images/crypto/hardhat.png'

export const desiredChain = process.env.REACT_APP_CHAIN

export const networks = [
    { name: 'Polygon', icon: PolygonIcon, id: 137, id_hex: "0x89", symbol: "MATIC" },
    { name: 'Polygon Mumbai', icon: PolygonIcon, id: 80001, id_hex: "0x13881", symbol: "MATIC" },
    { name: 'Hardhat', icon: HardhatIcon, id: 31337, id_hex: "0x7a69", symbol: "TST" }
]

export function getTargetNetwork() {
    return networks.find(n => n.id.toString() === desiredChain)
}

export const hardhatNetworkParams = {
    chainId: "0x7a69",
    chainName: "Hardhat",
    rpcUrls: ["http://127.0.0.1:8545"],
    nativeCurrency: {
        name: "TEST",
        symbol: "TST",
        decimals: 18,
    },
    // blockExplorerUrls: []
}

export const mumbaiNetworkParams = {
    chainId: "0x13881",
    chainName: "Polygon Mumbai",
    rpcUrls: [
        "https://rpc-mumbai.maticvigil.com",
    ],
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
}

export const polygonNetworkParams = {
    chainId: "0x89",
    chainName: "Polygon",
        rpcUrls: [
        "https://polygon-rpc.com",
        "https://rpc-mainnet.matic.network",
        "https://rpc-mainnet.matic.quiknode.pro",
        "https://matic-mainnet.chainstacklabs.com",
        "https://matic-mainnet-full-rpc.bwarelabs.com",
        "https://matic-mainnet-archive-rpc.bwarelabs.com",
        "https://poly-rpc.gateway.pokt.network/",
        "https://polygonapi.terminet.io/rpc",
    ],
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com/"]
}