import "react-toastify/dist/ReactToastify.css"
import Header from "./components/header"
import ContactInformation from "./components/contact-information"
import PaymentOptions from "./components/payment-options"
import CurrencyOptions from "./components/currency-options"
import MetamaskPayment from "./components/metamask-payment"
import Slippage from "./components/modals/slippage"
import PaymentInfo from "./components/payment-info"
import PaymentDetails from "./components/payment-details"
import { init } from "./helpers/metamask"
import { useMetaMask } from "metamask-react"
import { useSearchParams } from "react-router-dom"
import { createContext, useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import { useWindowSize } from "./hooks/useWindowsSize.js"
// import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { desiredChain, hardhatNetworkParams, mumbaiNetworkParams, networks, polygonNetworkParams } from "./helpers/networks"

export const MobileContext = createContext(false);

function App() {
  const windowSize = useWindowSize()
  const { status, account, chainId, connect, switchChain, addChain } = useMetaMask()
  const [step, setStep] = useState(2) // "payment-info-success") // 2
  const [searchParams] = useSearchParams()
  const [slippage, setSlippage] = useState(0.5)
  const [isMobile, setIsMobile] = useState(false)
  const [connected, setConnected] = useState(false)
  const [isRightChain, setIsRightChain] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [showSlippageModal, setShowSlippageModal] = useState(false)
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null)

  /**
   *  PayByLink - URL protocol definition
   *  15/06/2023 - v1.0.0
   *  -----------------------------------
      interface IPayByLinkURL {
        merchantId: string 
        merchantTerminalId: number // 0=Default merchant terminal
        invoiceNumber: string   // invoice number
        paymentId: string       // used to track transaction lifecycle
        totalAmount: number     // invoice total amount
        currency: string        // merchant base currency
        merchantData: string[]  // merchant data (example: ["Eligma d.o.o.", "Letališka cesta 33F", "1000 Ljubljana", "Slovenia", "SI17322090"]
        extraData?: string      // optional (example: utils.defaultAbiCoder.encode(["address", "uint256", "uint256", "bytes"], [address, tokenId, price, '0x']) )
        redirectURL?: string    // optional, url to redirect back to after payment
      }

      Example with no extraData:
      http://localhost:3001?tx=eyJtZXJjaGFudElkIjoiMHgwMDAxMDAwMDAwMDAwMDAwMDAwMjAwMDAwMDAwMDAwMTAwMDEwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAyIiwibWVyY2hhbnRUZXJtaW5hbElkIjowLCJpbnZvaWNlTnVtYmVyIjoiMjAyMy0wMDEiLCJwYXltZW50SWQiOiIxeXliWWIxV0VGSFFKbDRKcC1HX2oiLCJ0b3RhbEFtb3VudCI6IjIiLCJjdXJyZW5jeSI6IlVTRFQiLCJtZXJjaGFudERhdGEiOlsiV0IgVGVjaCIsIlN0cmVldCAxMyIsIjEyMzQ1IiwiQmlnIENpdHkiLCJCZWxnaXVtIl0sInJlZGlyZWN0VVJMIjoiaHR0cDovL2xvY2FsaG9zdDozMDAwIn0=

      Example from NFT Store: extraData { buyerAddress: "0x00", tokenId: 1, amount: 1 }
      http://localhost:3001?tx=JTdCJTIybWVyY2hhbnRJZCUyMiUzQSUyMjB4MDAwMTAwMDAwMDAwMDAwMDAwMDIwMDAwMDAwMDAwMDEwMDAyMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCUyMiUyQyUyMm1lcmNoYW50VGVybWluYWxJZCUyMiUzQTIlMkMlMjJpbnZvaWNlTnVtYmVyJTIyJTNBJTIybGp2ZVJlYU93Xzd5TVJmaXBubVpRJTIyJTJDJTIycGF5bWVudElkJTIyJTNBJTIyUWJCeFVfUEhjQW90cmxHbkZsdWN0JTIyJTJDJTIydG90YWxBbW91bnQlMjIlM0ElMjIxLjAlMjIlMkMlMjJjdXJyZW5jeSUyMiUzQSUyMkVVUiUyMiUyQyUyMm1lcmNoYW50RGF0YSUyMiUzQSU1QiUyMkVsaWdtYSUyMGQuby5vLiUyMiUyQyUyMkxldGFsaSVDNSVBMWthJTIwY2VzdGElMjAzM0YlMjIlMkMlMjIxMDAwJTIwTGp1YmxqYW5hJTIyJTJDJTIyU2xvdmVuaWElMjIlMkMlMjJTSTE3MzIyMDkwJTIyJTJDJTIyaW5mbyU0MGdvY3J5cHRvLmNvbSUyMiU1RCUyQyUyMmV4dHJhRGF0YSUyMiUzQSUyMjB4MDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwM2M0NGNkZGRiNmE5MDBmYTJiNTg1ZGQyOTllMDNkMTJmYTQyOTNiYzAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAxMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDA4MDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAlMjIlMkMlMjJyZWRpcmVjdFVSTCUyMiUzQSUyMiUyMiU3RA==

  */


  useEffect(() => {
    if (searchParams.get("tx")) {
      try {
        const params = JSON.parse(decodeURIComponent(window.atob(searchParams.get("tx"))))
        console.log(params)
        if (
          params.merchantId &&
          params.merchantTerminalId >= 0 &&
          params.invoiceNumber &&
          params.paymentId &&
          params.totalAmount > 0 &&
          params.currency &&
          params.merchantData
        ) {
          setPaymentDetails(params);
        } else {
          toast.error('Invalid payment link.')
          setPaymentDetails(null)
        }
      } catch (error) {
        toast.error('Oops. Something went wrong.')
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (windowSize.width < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize])

  useEffect(() => {
    if (window.location.pathname === "/success") {
      setStep("payment-info-success");
    }
    if (window.location.pathname === "/failed") {
      setStep("payment-info-failed");
    }
  }, [window.location])

  useEffect(() => {
    if (selectedPaymentOption?.name.toLowerCase() !== "metamask") return
    checkConnectionAndChain()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, chainId])

  useEffect(() => {
    if (!selectedPaymentOption) return
    if (selectedPaymentOption.name.toLowerCase() === "metamask" && connected && isRightChain) {
      init(account)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentOption])

  useEffect(() => {
    if (selectedPaymentOption?.name.toLowerCase() === "metamask" && connected && isRightChain) {
      init(account)
      setStep(3)
    }
  }, [selectedPaymentOption, connected, isRightChain, account])

  async function switchOrAddChain(chainId) {
    try {
      await switchChain(chainId)
    } catch (error) {
      if (error.code === 4902) {
        try {
          if (chainId === '0x7a69') {
            addChain(hardhatNetworkParams)
          } else if (chainId === '0x13881') {
            addChain(mumbaiNetworkParams)
          } else if (chainId === '0x89') {
            addChain(polygonNetworkParams)
          }
        } catch (error) {
          if (error.code === 4001) {
            toast.error("You've rejected the request.")
          } else {
            toast.error("Error adding chain")
          }
        }
      } else {
        toast.error("Error switching network")
      }
    }
  }

  async function checkConnectionAndChain() {
    if (status === "connected") {
      setConnected(true)
    } else {
      setConnected(false)
      connect()
    }

    if (!paymentDetails) return

    const _hexChainid = networks.find(n => n.id.toString() === desiredChain).id_hex

    if (chainId === _hexChainid) {
      setIsRightChain(true)
    } else {
      setIsRightChain(false)
      await switchOrAddChain(_hexChainid)
    }
  }

  function onPaymentSelection(option) {
    setSelectedPaymentOption(null)
    checkConnectionAndChain(option).then(() => {
      setSelectedPaymentOption(option)
    })
  }

  function onCurrencySelect(currency) {
    setSelectedCurrency(currency)
    setStep(4)
  }

  return (
    <MobileContext.Provider value={isMobile}>
      <ToastContainer />
      <Header showMetamask={true} />

      {showSlippageModal && (
        <Slippage
          closeModal={() => setShowSlippageModal(false)}
          currentSlippage={slippage}
          finalSlippageValue={(value) => setSlippage(value)}
        />
      )}

      <div className="bg-lightGray">
        <div className="calculated-fullheight flex flex-col lg:grid lg:grid-cols-2">
          {/* Left side */}
          {<PaymentDetails paymentDetails={paymentDetails} />}

          {/* Right side */}
          <section className={`relative bg-white pt-6 lg:pt-12 ${isMobile ? "modal-shadow rounded-t-lg" : ""}`}>
            <div className="w-ful h-100-48 px-4 lg:absolute lg:left-16 lg:w-[385px] lg:px-0">

              {step === 1 && <ContactInformation onSubmit={() => setStep(2)} onSkip={() => setStep(2)} />}

              {step === 2 && (<PaymentOptions onPaymentOptionSelection={(option) => onPaymentSelection(option)} />)}

              {step === 3 && (
                <CurrencyOptions
                  onCurrencyOptionSelection={(currency) => onCurrencySelect(currency)}
                  goBack={() => setStep(2)}
                  merchantId={paymentDetails.merchantId}
                  totalAmount={paymentDetails.totalAmount}
                />
              )}

              {step === 4 && (
                <MetamaskPayment
                  selectedCurrency={selectedCurrency}
                  slippage={slippage}
                  openSlippageModal={() => setShowSlippageModal(true)}
                  paymentDetails={paymentDetails}
                  goBack={() => setStep(3)}
                  showSuccessWindow={() => setStep("payment-info-success")}
                  showFailedWindow={() => setStep("payment-info-failed")}
                />
              )}

              {step === "payment-info-success" && <PaymentInfo success refferal={paymentDetails?.redirectURL}  />}
              {step === "payment-info-failed" && <PaymentInfo success={false} setStep={(e) => setStep(e)} />}
              {step === "payment-already-paid" && <h4> Payment already paid!</h4>}
            </div>
          </section>

          {isMobile && (
            <div className="bottom-[28px] flex h-full w-full items-end gap-x-4 bg-white py-3 pt-[34px] pb-5 text-[10px] lg:absolute">
              <p className="border-r border-borderGray pl-4 pr-4">Powered by GoCrypto © {new Date().getFullYear()}</p>
              <a href="http://public.gocrypto.com/dAPP/terms.pdf" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Terms</a>
              <a href="http://public.gocrypto.com/dAPP/privacy_policy.pdf" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Privacy</a>
              <a href="http://public.gocrypto.com/dAPP/cookies.pdf" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Cookies</a>
              <a href="mailto:info@elly.com" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Contact</a>
              {/* <span className="flex items-center gap-x-1 text-blueGreen">
                  EN <ChevronDownIcon className="h-3 w-3" stroke="#00ADBB" />
                </span> */}
            </div>
          )}
        </div>
      </div>
    </MobileContext.Provider>
  )
}

export default App
