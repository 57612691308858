import React, { useState, useEffect } from "react"
import Retryicon from "../assets/icons/retry-icon.svg"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { CheckIcon } from "@heroicons/react/24/solid"


export default function PaymentInfo(props) {
  return (
    <div className="flex py-12 w-full items-center justify-center overflow-hidden rounded-lg bg-[#F6FAFD] lg:w-[376px]">
      {props.success ? (
        <div className="flex flex-col items-center">
          <div className="success-gradient center-items mb-4 h-20 w-20 overflow-hidden rounded-full">
            <CheckIcon fill="#FFF" stroke="#fff" width={44} height={44} />
          </div>
          <p className="mb-2 text-lg font-medium">Payment successful</p>
          <p className="font-sm text-gray">The invoice has been paid.</p>
          <RedirectingBack refferal={props.refferal} />
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div className="failed-gradient center-items mb-4 h-20 w-20 overflow-hidden rounded-full">
            <XMarkIcon fill="#FFF" stroke="#fff" width={44} height={44} />
          </div>
          <p className="mb-2 text-lg font-medium">Payment unsuccessful</p>
          <p className="font-sm text-gray">An error occurred during the payment process.</p>
          <button className="center-items retry-shadow mt-10 h-[48px] w-full rounded-[4px] border border-[#E9EAED]" onClick={() => props.setStep(2)}>
            <img src={Retryicon} alt="Retry icon" className="mr-[10px] h-[14px] w-[14px]" /> Retry
          </button>
        </div>
      )}
    </div>
  )
}

const RedirectingBack = (props) => {
  const { refferal } = props
  const [stay, setStay] = useState(false);
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === -1 && !stay) {
      window.location.replace(refferal);
    }
  }, [seconds]);

  const handleCancel = () => {
    setStay(true)
  }

  if (!refferal || stay) return

  return (
    <div className="mt-6 text-center">
      <p className="font-sm text-gray mb-4">Redirecting you back to merchant...</p>
      <p className="font-medium text-gray mb-4">{seconds > 0  ? seconds : 0}</p>
      <button className="gray-button" onClick={handleCancel}>Stay on this page</button>
    </div>
  )
}