import React, { useContext } from "react"
import logo from "../assets/images/logo.svg"
import wallet from "../assets/images/wallet.svg"
import { MobileContext } from "../App"
import { useMetaMask } from 'metamask-react'
import { shortenAddress } from "../helpers/helpers"
import { getTargetNetwork } from "../helpers/networks"


export default function Header() {
  const isMobile = useContext(MobileContext)
  const { account, status } = useMetaMask()

  return (
    <div className="w-full h-[64px] bg-headerBlack header-shadow relative">
      <div className="w-full mx-auto max-w-7xl h-full flex justify-between items-center">

        <div className=" flex justify-center items-center h-full pl-6 lg:px-16 py-6">
          <a href="/" className="flex cursor-pointer">
            <img src={logo} alt="Logo" />
          </a>
        </div>

        <div className="flex h-full pl-0 lg:pl">

          <div className="flex justify-center items-center h-full py-6">
            {status === 'connected' && (
              <div className="flex relative z-10 h-7 lg:h-10">
                <div className="py-1 lg:p-1 rounded-full center-items overflow-hidden bg-headerBlack -mr-8 relative border border-darkCyanid">
                  <img src={getTargetNetwork().icon} alt="Binance" className="w-8 h-8 object-contain" />
                </div>
                <div className="py-[2px] flex items-center ">
                  <p className={`bg-darkCyanid text-sm rounded-[20px] ${isMobile ? '' : ''} flex items-center justify-between text-white pl-4 lg:pl-10 pr-2 lg:pr-4 h-full capitalize`}>
                    {!isMobile && <span className="text-white">{getTargetNetwork().name}</span>}
                  </p>
                </div>
              </div>
            )}
          </div>

          {status === 'connected' && (
            <div className="flex justify-center items-center h-full px-4 lg:pl-4 lg:pr-8 py-6">
              <div className="flex relative z-10 h-7 lg:h-10">
                <div className={`flex p-2 md:p-3 rounded-full center-items overflow-hidden bg-headerBlack -mr-6 md:-mr-8 relative border border-darkCyanid`}>
                  <img src={wallet} alt="Wallet" />
                </div>
                <div className="py-[2px] flex items-center">
                  <p className={`bg-darkCyanid text-sm rounded-[20px] flex items-center text-white pl-4 lg:pl-10 pr-2 lg:pr-4 h-full`}>
                    {!isMobile && <span className="text-white">{shortenAddress(account)}</span>}
                  </p>
                </div>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  )
}
