import React, { useEffect } from 'react'
import { useContext } from 'react'
import { MobileContext } from '../App'
import { ChevronDownIcon } from '@heroicons/react/24/outline'


export default function PaymentDetails(props) {
    const { paymentDetails } = props
    const isMobile = useContext(MobileContext)
    const [merchData, setMerchData] = React.useState(null)
    const [showMobilePaymentInfo, setShowMobilePaymentInfo] = React.useState(false)
    // const [showLanguageSelection, setShowLanguageSelection] = React.useState(false)

    useEffect(() => {
        if (paymentDetails?.merchantData)
            setMerchData(paymentDetails.merchantData)
    }, [paymentDetails])

    return (
        <section className='pt-8 lg:pt-12 pb-[10px] md:pb-0 border-r border-blueish small-right-shadow h-max lg:h-full relative px-4 lg:px-0'>
            {paymentDetails
                ?
                <div className='lg:absolute lg:right-16 w-full lg:w-[345px] h-max lg:h-[calc(100%-48px)]'>
                    <p className='text-gray text-sm mb-2'>Payment amount</p>
                    <p className='text-black text-3xl font-bold mb-2'>
                        {paymentDetails.currency === 'EUR' && '€ '}
                        {paymentDetails.currency === 'USDT' && '₮ '}
                        {Number(paymentDetails.totalAmount)}
                    </p>
                    <hr className='w-full text-borderGray h-[1px]' />

                    <div className='flex justify-between items-center mt-4 mb-6'>
                        <h6 className='font-medium text-lg'>Invoice details</h6>
                        {isMobile && <button onClick={() => setShowMobilePaymentInfo(!showMobilePaymentInfo)} className='text-[#00ADBB] border border-[#00ADBB] py-1 px-3 flex gap-x-3 items-center text-[10px] h-[22px] rounded-[4px]'>{showMobilePaymentInfo ? 'HIDE' : 'SHOW'} <ChevronDownIcon className='w-3 h-3' color="#00ADBB" stroke='#00ADBB' /></button>}
                    </div>

                    {(!isMobile || showMobilePaymentInfo) && (
                        <div>
                            {paymentDetails.invoiceNumber &&
                                <>
                                    <p className='mb-2'>Invoice number</p>
                                    <p className="text-gray text-sm mb-4">{paymentDetails.invoiceNumber}</p>
                                </>
                            }
                            <p className='mb-2'>Payment ID</p>
                            <p className="text-gray text-sm mb-4">{paymentDetails.paymentId}</p>
                            <p className='mb-2'>Merchant details</p>
                            {(merchData && merchData.length > 0) && merchData.map((eachField, index) => (<p className="text-gray text-sm mb-1" key={index}>{eachField}</p>))}
                            <hr className='w-full text-borderGray h-[1px] hidden lg:flex' />
                        </div>
                    )}
                </div>
                :
                <div className='center-items'>We have no payment information</div>
            }
            {/* Footer */}
            {!isMobile &&
                <div className="flex gap-x-4 lg:absolute bottom-[28px] right-16 text-[10px] w-max mt-[34px] pb-5">
                    <p className='border-r border-borderGray pl-4 pr-4'>Powered by GoCrypto © {new Date().getFullYear()}</p>
                    <a href="http://public.gocrypto.com/dAPP/terms.pdf" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Terms</a>
                    <a href="http://public.gocrypto.com/dAPP/privacy_policy.pdf" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Privacy</a>
                    <a href="http://public.gocrypto.com/dAPP/cookies.pdf" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Cookies</a>
                    <a href="mailto:info@elly.com" target="_blank" className='text-blueGreen cursor-pointer' rel="noreferrer">Contact</a>
                    {/* <span className='text-blueGreen flex items-center gap-x-1 relative cursor-pointer' onClick={() => setShowLanguageSelection(!showLanguageSelection)}>EN <ChevronDownIcon className='w-3 h-3' stroke="#00ADBB" />
                        {showLanguageSelection && <div className='absolute top-4 h-4 text-blueGreen flex items-center cursor-pointer'>DE</div>}
                    </span> */}
                </div>
            }
        </section>
    )
}
