import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'

const minSlippage = parseFloat(process.env.REACT_APP_MIN_SLIPPAGE)

export default function Slippage(props) {
  const [slippageOptions, setSlippageOptions] = useState([{ value: 0.1, checked: false }, { value: 0.5, checked: false }, { value: 1.0, checked: false }, { value: 2.0, checked: false }]);
  const [slippage, setSlippage] = useState(props.currentSlippage);
  const [customSlippage, setCustomSlippage] = useState(props.currentSlippage ? props.currentSlippage : minSlippage);

  useEffect(() => {
    const options = slippageOptions.map((option) => {
      if (option.value === slippage) {
        return { ...option, checked: true };
      }
      return { ...option, checked: false };
    }
    );
    setSlippageOptions(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slippage])

  function handleSlippageChange(event) {
    setCustomSlippage(minSlippage);
    setSlippage(event);
    props.finalSlippageValue(event);
  }

  function handleCustomSlippageChange(event) {
    if(event < minSlippage) {
      return ;
    }
    setSlippage(null);
    setCustomSlippage(Number(event).toFixed());
    props.finalSlippageValue(event);
  }


  return (
    <div className='fixed h-screen w-screen top-0 left-0 bg-[#00141B99] z-10 flex justify-center items-center'>
      <div className='w-[375px] bg-white z-20 rounded-lg px-6 py-9 modal-shadow'>
        <h5 className='flex w-full justify-between items-center font-medium text-[18px] mb-6'>Edit Slippage Tolerance <XMarkIcon className='w-6 h-6 cursor-pointer' onClick={() => props.closeModal()} /></h5>
        <p className='text-gray text-sm mb-6'>
          Setting a high slippage tolerance can result in a higher maximum payment amount. Use with caution.
        </p>
        <div className='grid grid-cols-2 gap-6'>
          {slippageOptions.map((option, index) => (
            <div className='flex justify-between items-center px-4 py-6 border border-lightBlueBorder rounded-lg slippage-item-shadow' key={index} onClick={() => handleSlippageChange(option.value)}>
              <p className=' text-sm'>{option.value}%</p>
              <div className='w-[24px] h-[24px] rounded-full border border-blueGreen flex justify-center items-center p-[2px]'>
                {option.checked && (<div className='w-[16px] h-[16px] rounded-full bg-blueGreen'></div>)}
              </div>
            </div>
          ))}
        </div>
        <h6 className='text-sm font-medium mb-4 mt-6'>Custom percentage</h6>

        <div className='flex justify-between items-center px-4 py-6 border border-lightBlueBorder rounded-lg slippage-item-shadow'>
              <input type="number" min={minSlippage} className={`${customSlippage < minSlippage ? 'border-red color-red': 'border-black' } w-4/5 text-sm outline-none border-b p-2`} placeholder={customSlippage+ '%'} value={customSlippage} onChange={(e) => handleCustomSlippageChange(e.target.value)} />
              <div className='w-[24px] h-[24px] rounded-full border border-blueGreen flex justify-center items-center p-[2px]'>
                {(customSlippage > 0 && customSlippage !== minSlippage) && (<div className='w-[16px] h-[16px] rounded-full bg-blueGreen'></div>)}
              </div>
            </div>
      </div>
    </div>
  )
}
