import React, { useState } from "react"
import QRCode from "react-qr-code"
import MetamaskIcon from "../assets/images/metamask.png"
import { utils } from "ethers"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { useMetaMask } from "metamask-react"
import { approve, processPayment } from "../helpers/metamask"
import { ArrowLeftIcon, Cog6ToothIcon } from "@heroicons/react/24/solid"

// import { io } from "socket.io-client";
// const socket = io.connect(config.socketUrl);

export default function MetamaskPayment(props) {
  const { selectedCurrency, slippage, paymentDetails } = props
  const { status } = useMetaMask()
  const [walletConnected, setWalletConnected] = useState(false)
  const [shownCurrency, setShownCurrency] = useState(selectedCurrency)
  const [amountWithSlippage, setAmountWithSlippage] = useState()
  const [isApprovalWaiting, setIsApprovalWaiting] = useState(false)
  const [routeTokenNames, setRouteTokenNames] = useState(selectedCurrency?.swapPath)
  const [isApproved, setIsApproved] = useState(false)
  const [blockCounter, setBlockCounter] = useState(null)

  useEffect(() => {
    // TODO Get data from chain...
    // socket.emit("estimate", paymentDetails.id);
    // socket.on("estimate", async (data) => {
    //   let estimateData = await data;
    //   Object.keys(estimateData).find((item) => {
    //     if (item == shownCurrency.currency && estimateData[item] != shownCurrency.price[0]) {
    //       const updatedLiveCurrency = {
    //         ...shownCurrency,
    //         liveAmount: estimateData[item].bestEstimate,
    //         swapPath: estimateData[item].bestSwapPath,
    //       };
    //       setShownCurrency(updatedLiveCurrency);
    //     }
    //   });
    // });
  }, [])

  useEffect(() => {
    if (status === "connected") {
      setWalletConnected(true);
    }
  }, [status]);

  useEffect(() => {
    const paymentAmount = shownCurrency.price
    const slippageAmount = paymentAmount * (slippage / 100)
    const totalAmount = Number(paymentAmount) + Number(slippageAmount)
    setAmountWithSlippage(totalAmount.toFixed(2))
  }, [slippage, shownCurrency.price])

  // TODO - Get SwapRoute from chain (offerTokenData)
  // useEffect(() => {
  //   (async () => {
  //     if (shownCurrency.swapPath) {
  //       let tokenNames = []
  //       shownCurrency.swapPath.forEach(async (token, index) => {
  //         const tokenName = await getSymbol(token)
  //         tokenNames[index] = tokenName
  //         if (tokenNames.length > 1) {
  //           const tokenNamesString = tokenNames.join(" > ")
  //           if (tokenNames == routeTokenNames) {
  //             return
  //           }
  //           setRouteTokenNames(tokenNamesString)
  //         }
  //       })
  //     }
  //   })()
  // }, [shownCurrency])

  async function sendOffer() {
    
    setIsApprovalWaiting(true);
    const paymentAmount = utils.parseUnits(paymentDetails.totalAmount.toString(), "ether")
    const paymentAmountMax = utils.parseUnits(amountWithSlippage.toString(), "ether")
    const merchantPaymentId = utils.formatBytes32String(paymentDetails.paymentId)

    try {
       await approve(selectedCurrency.address, utils.parseEther(amountWithSlippage))
       setIsApprovalWaiting(false)
       setIsApproved(true)
    } catch (error) {
       toast.error("Error occured while approving")
       setIsApprovalWaiting(false)
       return
    }

    processPayment(
      paymentDetails.merchantId,
      merchantPaymentId,
      paymentDetails.merchantTerminalId,
      paymentDetails?.extraData ?? [],
      paymentAmount,
      selectedCurrency.address, // userTokenInstanceAddress
      paymentAmountMax,         // offeredTokenAmountInMax
      []                        // selectedCurrency.swapPath // can be empty
    )
      .then((result) => {
        setBlockCounter(1);
        const provider = result.provider
        const txBlockNumber = result.blockNumber
        provider.on("block", (blockNumber) => {
          if (blockNumber > txBlockNumber) {
            setBlockCounter(blockCounter + 1)
          }
        })

        if (result.eventResult === "success") {
          props.showSuccessWindow()
        } else if (result.eventResult === "partial_success") {
          props.showSuccessWindow()
          toast.info("Payment succeeded partially. You will get a partial refund.")
        } else if (result.eventResult === "fail") {
          props.showFailedWindow()
          toast.info("Payment failed. Please contact merchant for a refund.")
        } else {
          props.showFailedWindow()
        }
        
      })
      .catch((error) => {
        if (error?.message.includes("ERC20: insufficient allowance")) {
          toast.info("Insufficient allowance. Try increasing approval amount.")
        } else if (error?.message.includes("PaymentRouter: Token amount required > Offered token max amount")) {
          toast.info("Offered amount to small. Try increasing slippage.")
        } else {
          toast.error("Error occured while processing payment")
        }
        props.showFailedWindow()
        return
      })
  }

  return (
    <section>
      <h4 className="mb-6 flex cursor-pointer items-center text-lg font-medium" onClick={() => props.goBack()}>
        <ArrowLeftIcon className="mr-4 h-[18px] w-[18px]" />
        Metamask payment
      </h4>

      <div className="flex flex-col items-center rounded-lg bg-metamaskLightBlue pt-10 pb-6">

        {shownCurrency.currency && (
          <p className="mb-1 text-[32px] font-medium text-center">
            {Number(shownCurrency.liveAmount).toFixed(8) || Number(shownCurrency.price).toFixed(8)} <span>{shownCurrency.name}</span>
          </p>
        )}

        <p className="mb-6 text-gray">{paymentDetails.totalAmount + " " + paymentDetails.currency}</p>

        {walletConnected ? (
          <div className="w-full">
            <div className="w-full px-4">
              <div className="w-full overflow-hidden rounded-lg bg-white">
                <p className="flex h-8 w-full items-center justify-between bg-[#C7F6ED] px-4">
                  CONNECTED WALLET <img src={MetamaskIcon} alt="Metamask" className="h-4 w-4 object-contain" />
                </p>
                <div className="flex items-center justify-between px-4 py-3">
                  <span className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <img src={shownCurrency.icon} alt={shownCurrency.name} className="mr-2 h-6 w-6" />
                      <p className="text-gray">{shownCurrency.currency}</p>
                    </div>
                    <p className="text-gray">{Number(selectedCurrency.balance).toFixed(8)}</p>
                  </span>
                </div>
              </div>
              <div className="my-4 flex w-full items-center justify-between">
                <span className="center-items cursor-pointer" onClick={() => props.openSlippageModal()}>
                  <Cog6ToothIcon className="h-5 w-5" />
                  <p className="ml-2 text-xs uppercase">slippage tolerance</p>
                </span>
                <p className="text-xs">{props.slippage}%</p>
              </div>
            </div>

            <div className="metamask-gradient-background w-full px-7 pt-4">
              <p className="mb-2 flex w-full justify-between text-gray">
                Est. time for payment <span className="text-darkerGray">0min 4s</span>
              </p>
              <p className="mb-2 flex w-full justify-between text-gray">
                Max. paid amount
                <span className="text-darkerGray">{amountWithSlippage + " " + shownCurrency.currency}</span>
              </p>
              {/* <p className='text-gray flex w-full justify-between mb-2'>
                Fee
                {(shownCurrency?.liveAmount || shownCurrency?.price) && (
                  <span className='text-darkerGray'>
                    {shownCurrency.liveAmount ? Number(shownCurrency.liveAmount) * 0.003 : Number(shownCurrency.price[0]) * 0.003} {shownCurrency.currency}
                  </span>
                )}
              </p> */}
              {/* <p className="mb-2 flex w-full justify-between text-gray">
                Route
                <span className="text-darkerGray">{routeTokenNames}</span>
              </p> */}

              <button
                className="yellow-button mt-[22px] disabled:pointer-events-none disabled:bg-[#D2DFE0]"
                disabled={isApproved || isApprovalWaiting || (selectedCurrency?.currency !== 'USDT' && !routeTokenNames)}
                onClick={() => sendOffer()}
              > 
                {isApprovalWaiting
                  ? "Waiting for approval"
                  : isApproved && !blockCounter
                    ? <div className="flex">Awaiting payment <div className="small-loader ml-3"></div></div>
                    : isApproved && blockCounter
                      ? <div className="flex">Confirming block {blockCounter} <div className="small-loader ml-3"></div></div>
                      : "Approve"}
                {isApprovalWaiting && <div className="small-loader ml-3"></div>}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full px-4">
            <div className="w-full overflow-hidden rounded-lg bg-white">
              <p className="flex h-8 w-full items-center justify-between bg-[#D2DFE0] px-4 uppercase">
                wallet not connected
                <img src={MetamaskIcon} alt="Metamask" className="h-4 w-4 object-contain grayscale" />
              </p>
              <div className="flex w-full items-center justify-center py-6 pb-4">
                <QRCode value="www.metamask.com" size={148} />
              </div>
              <p className="w-full pb-6 text-center">Scan QR with Metamask wallet</p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
